<!-- src/App.vue -->
<template>
  <div id="app">
    <router-view />
    <footer class="footer">
      <router-link to="/privacy">Privacy Policy</router-link>
    </footer>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  components: {
    // Remove HelloWorld component as it's no longer needed
  },
})
export default class App extends Vue {}
</script>

<style>
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f0f0f0;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #ddd;
}

.footer a {
  color: #000; /* Black color */
  font-size: 1rem;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* Ensure content doesn't overlap with the fixed footer */
#app {
  padding-bottom: 50px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
